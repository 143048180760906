<template>
  <div >
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      isPc:true
    }
  },
  created(){
    function is_pc() {
      var os = new Array("Android", "iPhone", "Windows Phone", "iPod", "BlackBerry", "MeeGo", "SymbianOS");
      var info = navigator.userAgent;
      var len = os.length;
      for (var i = 0; i < len; i++) {
        if (info.indexOf(os[i]) > 0) {
          return info;
        }
      }
      return false;
    }
    // 检测是手机端
    if (!is_pc()) {
      //为手机端则改配置
      console.log("pc端",is_pc())
    }else {
      this.isPc=false
      console.log("手机端")
    }
  },
}
</script>

<style>
.ddmaster {
  position: relative;
}

.ddmaster #footer {
  position: absolute;
  top: 110%;
  height: 20px;
  color: #3193f5;
  width: 100%;
  font-size: 0.8rem;
  text-align: right;
}
.center2{
  margin: 0 auto;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.center3{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textCenter{
  text-align: center;
}
.flex{
  display: flex;
}
h1,h2,h3,h4,h5,h6,html,body{
  margin: 0;
  padding: 0;
  font-weight: normal;
}
a{
  list-style: none !important;
  text-decoration: none;
}
ul,li,ol{
  text-decoration: none;
  list-style: none ;
}
</style>
