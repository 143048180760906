import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//重复点击路由导航报错
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

export default new Router({
    routes: [
        {
            path: '/',
            component: () => import("@/views/home"),
            children: [
                {
                    path: '/Himalayan',
                    name: 'Himalayan',
                    component: () => import("@/views/Himalayan/Himalayan"),
                },
                {
                    path: '/home',
                    name: 'HomeFirst',
                    component: () => import("@/views/home/HomeFirst"),
                },{
                    path: '/increment',
                    name:"increment",
                    component: () => import("@/views/increment/increment"),
                },
                {
                    path: '/teambition',
                    name:"teambition",
                    component: () => import("@/views/increment/Jump/teambition"),
                },
                {
                    path: '/BrainOrganization',
                    name:"BrainOrganization",
                    component: () => import("@/views/increment/Jump/BrainOrganization"),
                },
                {
                    path: '/HaveSuccess',
                    name:"HaveSuccess",
                    component: () => import("@/views/increment/Jump/HaveSuccess"),
                }, {
                    path: '/cloud',
                    name:"cloud",
                    component: () => import("@/views/increment/Jump/cloud"),
                },{
                    path: '/wages',
                    name:"wages",
                    component: () => import("@/views/increment/Jump/wages"),
                },{
                    path: '/newspaper',
                    name:"newspaper",
                    component: () => import("@/views/increment/Jump/newspaper"),
                },{
                    path: '/vacation',
                    name:"vacation",
                    component: () => import("@/views/increment/Jump/vacation"),
                },{
                    path: '/Gang',
                    name:"Gang",
                    component: () => import("@/views/increment/Jump/Gang"),
                },
                {
                    path: '/BOSS',
                    name:"BOSS",
                    component: () => import("@/views/increment/Jump/BOSS"),
                },
                {
                    path: '/Gateway',
                    name:"Gateway",
                    component: () => import("@/views/increment/Jump/Gateway"),
                },  {
                    path: '/suitables',
                    name:"suitables",
                    component: () => import("@/views/increment/Jump/suiitable"),
                },
                {
                    path: '/intelligence',
                    name:"intelligence",
                    component: () => import("@/views/increment/Jump/intelligence"),
                },
                {
                    path: '/coolJump',
                    name:"coolJump",
                    component: () => import("@/views/increment/Jump/coolJump"),
                },
                {
                    path: '/Conferencing',
                    name:"Conferencing",
                    component: () => import("@/views/increment/Jump/Conferencing"),
                },
                {
                    path: '/CRM',
                    component: () => import("@/views/increment/Jump/CRM"),
                },
                {
                    path: '/Teaching',
                    component: () => import("@/views/increment/Jump/Teaching"),
                },
                {
                    path: '/Himalayan',
                    name: 'Himalayan',
                    component: () => import("@/views/Himalayan/Himalayan"),
                },
                {
                    path: '/Harry',
                    name: 'Harry',
                    component: () => import("@/views/Harry/Harry"),
                },
                {
                    path: '/Unexpectedly',
                    name: 'Unexpectedly',
                    component: () => import("@/views/Unexpectedly/Unexpectedly"),
                },
                {
                    path: '/Unexpectedly',
                    name: 'Unexpectedly',
                    component: () => import("@/views/Unexpectedly/Unexpectedly"),
                },
                {
                    path: '/left',
                    name: 'left',
                    component: () => import("@/views/left/left"),
                },
                {
                    path: '/rights',
                    name: 'rights',
                    component: () => import("@/views/right/right"),
                },
                {
                    path: '/Hippo',
                    name: 'Hippo',
                    component: () => import("@/views/Hippo/Hippo"),
                },
                {
                    path: '/CaseListing',
                    name: 'CaseListing',
                    component: () => import("@/views/CaseListing/CaseListing"),
                },
                {
                    path: '/placeOrder',
                    name: 'placeOrder',
                    component: () => import("@/views/placeOrder/placeOrder"),
                },
                {
                    path: '/major',
                    name: 'major',
                    component: () => import("@/views/major/major"),
                },
                {
                    path: '/Exclusive',
                    name: 'Exclusive',
                    component: () => import("@/views/Exclusive/Exclusive"),
                },
                {
                    path: '/Suitable',
                    name: 'Suitable',
                    component: () => import("@/views/Suitable/Suitable"),
                },
                {
                    path: 'TeambiTions',
                    name: 'TeambiTions',
                    component: () => import("@/views/SolutionComponents/Teambition"),
                },
                {
                    path: 'campus',
                    name: 'campus',
                    component: () => import("@/views/SolutionComponents/campus"),
                },
                {
                    path: 'OKR',
                    name: 'OKR',
                    component: () => import("@/views/SolutionComponents/OKR"),
                },
                {
                    path: 'CoolCollege',
                    name: 'CoolCollege',
                    component: () => import("@/views/SolutionComponents/CoolCollege"),
                },
                {
                    path: 'CRMs',
                    name: 'CRM',
                    component: () => import("@/views/SolutionComponents/CRM"),
                },
                {
                    path: 'News',
                    name: 'News',
                    component: () => import("@/views/News/News"),
                },
                {
                    path: 'DetailsNews',
                    name: 'DetailsNews',
                    component: () => import("@/views/detailsNews/detailsNews"),
                },
                {
                    path: 'tutorial',
                    name: 'tutorial',
                    component: () => import("@/views/tutorial/tutorial"),
                },
                {
                    path: 'NailingAt',
                    name: 'NailingAt',
                    component: () => import("@/views/NailingSAt/NailingAt"),
                },
                {
                    path: 'HotEvent',
                    name: 'HotEvent',
                    component: () => import("@/views/HotEvent/HotEvent"),
                },
                {
                    path: 'Structures',
                    name: 'Structures',
                    component: () => import("@/views/Strctures/Structures"),
                },
                {
                    path: 'Training',
                    name: 'Training',
                    component: () => import("@/views/Training/Training"),
                },
                {
                    path: 'information',
                    name: 'information',
                    component: () => import("@/views/information/information"),
                },
                {
                    path: 'about',
                    name: 'about',
                    component: () => import("@/views/about/about"),
                },  {
                    path: 'ContactUs',
                    name: 'ContactUs',
                    component: () => import("@/views/ContactUs/ContactUs"),
                }, {
                    path: 'Solution',
                    name: 'Solution',
                    component: () => import("@/views/Solution/Solution"),
                },
                {
                    path: 'SolutionS',
                    name: 'SolutionS',
                    component: () => import("@/views/Solution/SolutionS"),
                },
                {
                    path: 'hardWare',
                    name: 'hardWare',
                    component: () => import("@/views/Solution/hardWare"),
                },{
                    path: 'Customer',
                    name: 'Customer',
                    component: () => import("@/views/Solution/Customer"),
                },
            ]
        },
        //双十一活动
        {
            path: '/RidingLantern',
            component: () => import("@/views/RidingLantern"),
        },
        {
            path: '/:notfond',
            component: () => import("@/components/FOF"),
        },

    ],
    mode:'history'
})
