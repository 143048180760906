// 配置vuex的所有
/*
* 1/ 利用Vue.use来注入插件
* */
import Vuex, {Store} from 'vuex';
import vue from 'vue';

// 注册vuex到vue中
vue.use(Vuex);

// 导入vuex-persistedstate
import save from 'vuex-persistedstate';

// export default new Vuex.Store()
export default new Store({
  state() { // 数据
    return {
      show: 1,
      dialogVisible:false,
      kind:0
    }

  },

  getters: {},

  mutations: {},


  actions: {},
  // 插件（侦听state的变化，从storage中读取数据放入state中）

  plugins: [save({
    storage: sessionStorage // 更改存储storage【默认是localStorage】
    , key: 'happy_admin'
  })]
})
